/** @format */

import { getModule } from 'vuex-module-decorators'
import Api from '@/models/Api'
import store from '@/store'
import AgreementModule from '@/store/model/AgreementModule'
import moment from 'moment'
import SystemtModule from '@/store/SystemModule'
import ApiParameters from '@/models/interface/ApiParameters'
import Company from './Company'
import User from './User'
import PaginateOptions from './interface/PaginateOptions'
import WebMessage from './WebMessage'

export default class Agreement {
  public id: string | null = null

  public name: string = ''

  public type: string = 'agreement'

  public number: number = 0

  public client_id: string = ''

  public owner_id: string | null = null

  public owner_company_id: string | null = null

  public signed_at: string = moment().format('YYYY-MM-DD HH:mm:ss')

  public created_at: string = ''

  public external_document_id: string | null = null

  private _client: Company | null = null

  public get client(): Company | null {
    if (
      this.client_id
      && (!this._client || this._client.id != this.client_id)
    ) {
      this._client = Company.find(this.client_id)
    }
    return this._client
  }

  private _owner: User | null = null

  public get owner(): User | null {
    if (!this._owner || this._owner.id != this.owner_id) {
      if (this.owner_id) this._owner = User.find(this.owner_id)
    }
    return this._owner
  }

  private _owner_company: Company | null = null

  public get owner_company(): Company | null {
    if (
      !this._owner_company
      || this._owner_company.id != this.owner_company_id
    ) {
      if (!this.owner_company_id) {
        const system = getModule(SystemtModule)
        if (system.user) this.owner_company_id = system.user.company_id
      }
      if (this.owner_company_id) this._owner_company = Company.find(this.owner_company_id)
    }
    return this._owner_company
  }

  public save(file: any = null) {
    const api = new Api()

    if (this.id) {
      const data = {
        type: this.type,
        client_id: this.client_id,
        owner_id: this.owner_id,
      }

      return api
        .post(`agreement/${this.id}`, data)
        .then(this.onSave)
        .catch(this.onError)
    }
    const data: ApiParameters = {
      name: this.name,
      type: this.type,
      client_id: this.client_id,
      signed_at: this.signed_at,
      owner_id: this.owner_id,
      external_document_id: this.external_document_id,
      file,
    }

    if (file) {
      delete data.external_document_id
    } else {
      delete data.file
      delete data.signed_at
    }
    return api
      .form('agreement', data)
      .then(this.onSave)
      .catch(this.onError)
  }

  public delete() {
    const api = new Api()

    return api
      .delete(`agreement/${this.id}`, {})
      .then(this.onDelete)
      .catch(this.onError)
  }

  private onSave(response: any) {
    const agreement = Agreement.toObject(response.data.result.agreement)

    WebMessage.success(
      `IO #${agreement.number} "${agreement.name}" saved!`,
    )

    return response
  }

  private onDelete(response: any) {
    const agreements = Agreement.filter(response.data.result.deleted)

    let message

    if (agreements.length == 1) {
      message = `IO #${agreements[0].number} "${agreements[0].name}" deleted!`
    } else {
      message = 'IOs deleted!'
    }

    WebMessage.success(message)

    Agreement.module.delete(agreements)

    return response
  }

  private onError(error: any) {
    return error
  }

  public static toObject(data: any, cache: boolean = true): Agreement {
    const agreement = new Agreement()

    agreement.id = data.id
    agreement.name = data.name
    agreement.type = data.type
    agreement.number = data.number
    agreement.client_id = data.client_id
    agreement.owner_id = data.owner_id
    agreement.owner_company_id = data.owner_company_id
    agreement.signed_at = data.signed_at
    agreement.created_at = data.created_at
    agreement.external_document_id = data.external_document_id

    if (data.client) {
      Company.toObject(data.client)
    }

    if (data.owner_company) {
      Company.toObject(data.owner_company)
    }

    if (data.owner) {
      User.toObject(data.owner)
    }

    //  Cache Object List
    if (cache) Agreement.module.update(agreement)

    return agreement
  }

  public static toObjectList(data: any, cache: boolean = true): Agreement[] {
    const agreements = new Array<Agreement>()
    data.forEach((value: any) => {
      const agreement = Agreement.toObject(value, false)
      agreements.push(agreement)
    })

    //  Cache Object List
    if (cache) Agreement.module.update(agreements)

    return agreements
  }

  /// State Management
  public static get module(): AgreementModule {
    if (!store.hasModule('agreement')) {
      store.registerModule('agreement', AgreementModule)
    }

    return getModule(AgreementModule)
  }

  public static find(id: string): Agreement | null {
    const o = Agreement.module.data.find(agreement => agreement.id === id)
    return o instanceof Agreement ? o : null
  }

  public static filter(filter: string[]): Agreement[] {
    if (Array.isArray(filter)) {
      return Agreement.module.data.filter(
        agreement => agreement.id && filter.includes(agreement.id),
      )
    }
    return Agreement.module.data.filter(filter)
  }

  public static async get(id: string): Promise<Agreement | null> {
    return Agreement.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return Agreement.module.paginate(options)
  }
}
